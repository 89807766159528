import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Box, Autocomplete, TextField, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { debounce as _debounce } from 'lodash';
import { links } from '/src/domain/shared/links';
import { useTranslation } from 'react-i18next';

interface TagSearchProps {
  tagParameter?: string;
  getTags: (tag?: string) => Promise<string[]>;
}

const INTERVAL_BETWEEM_REQUESTS = 500;

export const TagSearch: FunctionComponent<TagSearchProps> = ({
  tagParameter,
  getTags,
}) => {
  const { t } = useTranslation();
  const [tagsOptions, setTagsOptions] = useState<string[]>([]);
  const [progress, setProgress] = useState(false);

  const getTagsOptions = async (tags?: string) => {
    setProgress(true);
    const responseTags = await getTags(tags);
    setTagsOptions(responseTags);
    setProgress(false);
  };

  useEffect(() => {
    getTagsOptions();
  }, []);

  const debounceTags = useCallback(
    _debounce(
      (tags?: string) => getTagsOptions(tags),

      INTERVAL_BETWEEM_REQUESTS,
    ),
    [],
  );

  const handleAutocompleteInput = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    debounceTags(value);
  };


  return (
    <Box
      component={'form'}
      action={`${links.origin}/tag`}
      sx={{ display: 'flex' }}
    >
      <Autocomplete
        options={tagsOptions}
        loading={progress}
        loadingText={t('loading')}
        noOptionsText={t('noResults')}
        defaultValue={tagParameter}
        sx={{ flexGrow: 1 }}
        size="small"
        freeSolo
        ListboxProps={{ style: { maxHeight: '10rem' } }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleAutocompleteInput}
            label={t('searchByTag')}
            placeholder={t('favorites')}
            name="q"
          />
        )}
      />
      <IconButton sx={{ ml: '0.5rem' }} color="primary" type="submit">
        <CheckIcon />
      </IconButton>
    </Box>
  );
};
