import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { Autocomplete, SxProps, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce as _debounce } from "lodash";
import cs from "classnames";

interface TagsFieldProps {
    studentId?: string;
    tags: string[];
    setTags: (newTags: string[]) => void;
    getTags: (studentId?: string, tag?: string) => Promise<string[]>;
    variant?: "filled" | "outlined" | "standard"
    className?: string;
    sx?: SxProps;
    limitTags?: number | null;
}


const INTERVAL_BETWEEM_REQUESTS = 500;


const checkHashtagCharValidity = (char: string) => {
    return /[a-zA-Zа-яА-Я0-9_\-]/.test(char);
}

const clearHashtag = (value: string) => {
    return value.replace(/[^a-zA-Zа-яА-Я0-9_\-]/g, '');
}

export const TagsField: FunctionComponent<TagsFieldProps> = ({
    studentId,
    tags,
    setTags,
    getTags,
    variant,
    className,
    sx,
    limitTags,
}) => {
    const { t } = useTranslation();
    const [tagsOptions, setTagsOptions] = useState<string[]>([]);
    const [progress, setProgress ] = useState(false);

    const getTagsOptions = async (tags?: string) => {
        setProgress(true);
        const responseTags = await getTags(studentId, tags);
        setTagsOptions(responseTags);
        setProgress(false);
    }

    useEffect(() => {
        getTagsOptions();
    }, [])

    const debounceTags = useCallback(_debounce(
        (tags?: string) => getTagsOptions(tags),

        INTERVAL_BETWEEM_REQUESTS
    ), [])

    const handleAutocompleteInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const lastChat = value.charAt(value.length-1);

        if (value.length > 0 && !checkHashtagCharValidity(lastChat)) {
            e.target.blur();
            e.target.focus();
        }
        debounceTags(value);
    }

    const handleAutocomplete = (newValue: string[]) => {
        const isMoreTags = tags.length < newValue.length;

        if (isMoreTags) {
            const lastElemIndex = newValue.length - 1;
            const newTags = newValue.slice(0, lastElemIndex);
            const cleanHashtag = clearHashtag(newValue[lastElemIndex]);
            newValue = cleanHashtag.length ? [...newTags, cleanHashtag] : newTags;
        }

        setTags(newValue);
        getTagsOptions();
    }

    return (
        <Autocomplete
            multiple
            options={tagsOptions}
            filterSelectedOptions
            value={tags}
            className={cs(className)}
            loading={progress}
            onChange={(event, newValue) => {
                handleAutocomplete([...newValue])
            }}
            loadingText={ t('loading') }
            freeSolo
            autoSelect
            limitTags={limitTags === null ? undefined : limitTags || 2}
            size="small"
            ListboxProps={{ style: { maxHeight: '10rem' } }}
            sx={{
                '& .MuiAutocomplete-tag>span': {
                    paddingLeft: 0,
                    '&::before': {
                        content: "'#'",
                        paddingLeft: "1rem",
                    }
                },
                ...sx,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant || "standard"}
                    onChange={handleAutocompleteInput}
                    label={ t('tags') }
                    placeholder={ t('favorites') }
                />
            )}
        />
    )
}

