import { TrainerBaseInfo } from "/src/domain/shared/TrainerBaseInfo";
import { User } from "/src/domain/shared/User";
import { StudentBaseInfo } from "/src/domain/shared/StudentBaseInfo";
import { FastestBestDataset, PanoDataset, PredictionDataset } from "/src/domain/personalStatistics/PersonalStatisticsData";
import { Club } from "/src/domain/club/ClubData";

export interface StudentProfileData {
    studentInfo: StudentInfo;
    trainerInfo?: TrainerInfo;
    records: DistanceRecord[];
    results: Result[];
    metrics?: Metrics;
    friendsNearby?: FriendNear[];
    services: Service[];
}

export interface TrainerInfo extends TrainerBaseInfo {
    chatLink?: string;
}


export interface StudentInfo extends StudentBaseInfo {
    gender?: Gender;
    team?: string;
    birthDate?: string;
    city?: string;
    email?: string;
    telegramNick?: string;
    bio?: string;
    isTrainerPayed: boolean;
    paysite?: string;
    paytrainer?: string;
    commentForTrainer?: string;
    fastestbest?: FastestBestDataset;
    pano?: PanoDataset;
    pret42?: PredictionDataset;
    botSettings?: string;
    trainerAccount: TrainerAccountData;
}

export enum Gender {
    MALE = "M",
    FEMALE = "F",
    NOTSELECTED = 0
}

export interface BotSettings {
    telemetry: boolean;
    summary: boolean;
    trackBest: boolean;
    lowCadence: boolean;
    highHR: boolean;
    recLowCadence: boolean;
    recHighHR: boolean;
    sendnotifyimmediately?: boolean,
    sendnotifyoneday?: boolean
}

export { Club };


export interface DistanceRecord {
    key: string;
    resultTime?: string;
    year: number | string;
    location: string;
    errors?: Partial<Record<"resultTime" | "year" | "location", boolean>>;
}

export interface Result {
    id?: number;
    km: Distance;
    mainRace: boolean;
    personalBest: boolean;
    resultTime: string;
    date: string;
    location: string;
    errors?: ResultErrors;
}

export type ResultErrors = Partial<Record<"date" | "location" | "resultTime" | "isRaceExists" | "isDateEmpty" | "resultTime" | "isResultTimeEmpty", boolean>>

export interface FriendNear {
    id: string;
    firstName: string;
    lastName: string;
    km: number;
    avatarUrl?: string;
    resultTime: string;
}

export interface Service {
    name: ServiceName;
    isConnected: boolean;
    connectionLink: string;
    id?: string;
}

export enum ServiceName {
    STRAVA = "strava",
    POLAR = "polar",
    GARMIN = "garmin"
}

export interface Metrics {
    height?: number;
    weight?: number;
    maxhr?: number;
    resthr?: number;
    lthr?: number;
    health?: string;
}


export interface NameBlockData {
    lastName: string;
    avatarUrl?: string;
    telegramNick?: string;
    email?: string;
    city?: string;
    birthDate?: string;
    gender?: Gender;
    bio?: string;
    team?: string;
}

export interface TrainerAccountData {
    id?: string;
    isConnected?: boolean;
}

export type Settings = Pick<StudentProfileUser, "confidentiality" | "cryptoWallet">

export enum Distance {
    BEST5KM = 5,
    BEST10KM = 10,
    BESTM2 = 21.1,
    BESTM = 42.2
}

export interface StudentProfileUser extends User {
    readonly telegramPin?: TelegramPin;
    readonly cryptoWallet?: string;
    readonly confidentiality?: Confidentiality;
    readonly emailok: boolean;
    readonly email: string;
    readonly isGod: boolean;
}

export interface TelegramPin {
    readonly pin: number;
    readonly validUntil: string;
}

export interface Confidentiality {
    isOpenView: boolean;
    isEmailUnsub: boolean;
    isShowLocation: boolean;
}

export interface BadgeData {
    id: number;
    name: string;
    counter: number;
    minted: number;
    imgUrl: string;
    labels?: string;
}

export interface SportmasterPhoneNumberData {
    phone?: string | null;
}

export interface UserProtocols5k {
    challenge_id: number;
    challenge_key: string;
    name: string;
    track: {
        pace: string;
        stravats: string;
        mt: number;
        mtage: number;
    }
}