import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  TrainingTask,
  PageSection,
  TrackType,
  StudentInfo,
  Report,
  ReportParameters,
  Track,
  TrainingType,
} from '/src/domain/studentTasks/StudentTasksData';
import { User } from '/src/domain/shared/User';
import { TaskHeader } from '/src/ui/studentTasks/studentTasks/TaskHeader';
import { TrackView } from '/src/ui/studentTasks/studentTasks/TrackView';
import { TrainingTypeView } from '/src/ui/studentTasks/studentTasks/TrainingTypeView';
import { KmForecast } from '/src/ui/studentTasks/studentTasks/KmForecast';
import { ReportButton } from '/src/ui/studentTasks/studentTasks/sendReport/ReportButton';
import { SendReport } from '/src/ui/studentTasks/studentTasks/sendReport/SendReport';
import { ReportParametersView } from '/src/ui/studentTasks/studentTasks/ReportParametersView';
import CheckIcon from '@mui/icons-material/Check';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '/src/assets/theme';
import cs from 'classnames';
import { startReportTimer } from '/src/domain/studentTasks/startReportTimer';
import { useTranslation } from 'react-i18next';
import { isNumber as _isNumber } from 'lodash';
import dayjs from 'dayjs';

interface TaskProps {
  studentInfo?: StudentInfo;
  task: TrainingTask;
  isDiary?: boolean;
  lastFromTrainer?: number;
  currentDate: string;
  readOnly: boolean;
  isTribunalUser: boolean;
  trainerId?: string;
  currentUser?: User;
  sendTaskReport?: (
    report: Report,
    reportParameters: ReportParameters,
    task: TrainingTask,
  ) => Promise<Boolean>;
  getTags: (studentId?: string, tag?: string) => Promise<string[]>;
}

export type TimerType = number | boolean;

// if less, the timer turns red (seconds)
const timeLimitWarning = 43200;

// if less, show timer
const timeShowTimer = 86400;

export const TaskView: FunctionComponent<TaskProps> = ({
  studentInfo,
  task,
  isDiary,
  lastFromTrainer,
  currentDate,
  readOnly,
  isTribunalUser,
  trainerId,
  currentUser,
  sendTaskReport,
  getTags,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [timer, setTimer] = useState<TimerType>(false);
  const [isExpanded, expand] = useState(false);

  const [isReportSent, setReportSent] = useState(
    Boolean(task.reportParameters),
  );

  const [reportParameters, setReportParameters] = useState(
    task.reportParameters,
  );

  const isTimeLimitWarning =
    _isNumber(timer) && !task.reportParameters && timer <= timeLimitWarning;
  const isTimeShowTimer = _isNumber(timer) && timer < timeShowTimer;

  const isExternalTask = Boolean(task.studentInfo);
  const taskOwner = task.studentInfo || studentInfo;

  const isStatisticsCheckbox: boolean =
    isWithinTwoDays(task.date, currentDate) &&
    ((currentUser && currentUser?.id === trainerId) ||
      (!readOnly && !currentUser?.currentTrainerId)) &&
    !!taskOwner;

  useEffect(() => {
    const intervalId = startReportTimer({
      isDiary,
      isBigReport: task.type === TrainingType.COMPETITION,
      readOnly,
      isExternalTask,
      setTimer,
      taskDate: task.date,
      currentDate,
      hasReport: Boolean(task.reportParameters),
    });
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);

  let isOneRunningTrackDisplayed = false;

  return (
    <div
      id={task.today ? PageSection.TODAY : ''}
      className={cs(
        classes.taskContainer,
        { [classes.taskContainerWarning]: isTimeLimitWarning },
        { [classes.todayTaskContainer]: task.today },
        { [classes.externalTaskContainer]: isExternalTask },
      )}
    >
      <TaskHeader
        currentDate={currentDate}
        date={task.date}
        studentInfo={task.studentInfo}
        nearKm={task.nearkm}
        currentUserId={currentUser?.id}
      />
      <TrainingTypeView type={task.type} />
      {Boolean(task.comment.length) && (
        <div className={classes.comment}>{task.comment}</div>
      )}
      {reportParameters && (
        <div className={classes.reportContainer}>
          <ReportParametersView
            studentId={taskOwner?.id}
            reportParameters={reportParameters}
          />
        </div>
      )}
      <div className={classes.runView}>
        <div>
          <KmForecast
            readOnly={readOnly}
            forecast={task.target}
            tracks={task.tracks}
          />
        </div>
        {task.tracks?.map((track) => {
          const isDisplayTrack =
            currentUser?.isPremium ||
            isTribunalUser ||
            (track.type === TrackType.RUN && !isOneRunningTrackDisplayed);

          if (isDisplayTrack) {
            isOneRunningTrackDisplayed = true;

            return (
              <TrackView
                studentId={taskOwner?.id}
                key={track.id}
                track={track}
                isReadOnly={readOnly}
                isStatisticsCheckbox={isStatisticsCheckbox}
                currentUser={currentUser}
              />
            );
          }

          return (
            <TrackView
              studentId={taskOwner?.id}
              key={track.id}
              track={track}
              isStub
              isReadOnly={readOnly}
              isStatisticsCheckbox={isStatisticsCheckbox}
              currentUser={currentUser}
            />
          );
        })}
        {_isNumber(task.percentageCompleted) && (
          <div className={classes.percentageCompleted}>{`${t('execution')}: ${
            task.percentageCompleted
          }%`}</div>
        )}
        {Number(task.reportParameters?.ts) < Number(lastFromTrainer) && (
          <CheckIcon className={classes.checkIcon} />
        )}
      </div>
      {taskOwner && (
        <>
          <ReportButton
            timer={timer}
            isTimeShowTimer={isTimeShowTimer}
            isTimeLimitWarning={isTimeLimitWarning}
            isReportSent={isReportSent}
            onClick={() => expand(true)}
            className={classes.reportBtn}
          />
          {currentUser && sendTaskReport && (
            <SendReport
              studentId={taskOwner.id}
              currentUser={currentUser}
              timer={timer}
              isTimeShowTimer={isTimeShowTimer}
              isTimeLimitWarning={isTimeLimitWarning}
              isReportSent={isReportSent}
              setReportSent={() => setReportSent(true)}
              reportParameters={reportParameters}
              setReportParameters={setReportParameters}
              isExpanded={isExpanded}
              task={task}
              hide={() => expand(false)}
              sendTaskReport={sendTaskReport}
              getTags={getTags}
            />
          )}
        </>
      )}
    </div>
  );
};

function isWithinTwoDays(startDate: string, currentDate: string) {
  const differenceInDays = dayjs(currentDate).diff(dayjs(startDate), 'day');
  return differenceInDays < 2;
}

const useStyles = makeStyles((muiTheme) => ({
  taskContainer: {
    margin: '1rem 0',
    padding: '1rem 1.2rem',
    borderRadius: '5px',
    backgroundColor: theme.lightPrimaryColor,
  },
  todayTaskContainer: {
    border: `1px solid ${theme.lightGreenColor}`,
  },
  externalTaskContainer: {
    border: `1px solid ${theme.secondaryColor}`,
  },
  taskContainerWarning: {
    backgroundColor: theme.lightSecondaryColor,
  },
  comment: {
    width: '95%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-wrap',
    padding: '0.75rem',
    borderRadius: '5px',
    backgroundColor: theme.primarySurfaceColor,
    marginTop: '0.75rem',
  },
  reportContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0.75rem',
  },
  reportBtn: {
    marginTop: '0.75rem',
  },
  runView: {
    position: 'relative',
    marginTop: '0.75rem',
  },
  checkIcon: {
    position: 'absolute',
    top: '-1rem',
    right: '0',
    fontSize: '4rem',
    color: theme.lightGreenColor,
  },
  percentageCompleted: {
    display: 'inline-block',
    minWidth: '10rem',
    borderRadius: '5px',
    backgroundColor: theme.primarySurfaceColor,
    padding: '0.25rem 0.5rem',
    marginTop: '0.5rem',
  },
}));
