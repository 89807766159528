import React, { FunctionComponent, useState, useContext } from "react";
import { PageContext, PageContextProps } from "/src/contexts/Page";
import { User } from "/src/domain/shared/User";
import { Track, TrackType } from "/src/domain/studentTasks/StudentTasksData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { Link, FormControlLabel, Checkbox, CircularProgress, Box } from "@mui/material";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import { Shoes } from "./icons/Shoes"
import NordicWalkingIcon from '@mui/icons-material/NordicWalking';
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import { OtherActivity } from "./icons/OtherActivity"
import { ActionsView } from "./actions/ActionsView";
import { ActivityMetrics } from "./ActivityMetrics";
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "/src/assets/theme";
import { useTranslation } from "react-i18next"
import { sendEvent, EventType } from "/src/domain/shared/analytics";
import { isNumber as _isNumber } from "lodash";
import { sendSkipForStat } from "/src/domain/shared/requests/sendSkipForStat";
import { links } from "/src/domain/shared/links";


type TrackProps = {
    studentId?: string;
    track: Track;
    isReadOnly: boolean;
    isStatisticsCheckbox: boolean;
    isStub?: boolean;
    currentUser?: User;
} 

const getIcon = (value?: TrackType) => {
    if (value === TrackType.RUN) {
        return <Shoes/>;
    }
    if (value === TrackType.WALK) {
        return <NordicWalkingIcon/>;
    }
    if (value === TrackType.SWIM) {
        return <PoolIcon/>;
    }
    if (value === TrackType.RIDE) {
        return <DirectionsBikeIcon/>;
    }
    if (value === TrackType.SKI) {
        return <DownhillSkiingIcon/>;
    }
    if (value === TrackType.AUTO) {
        return <DirectionsCarOutlinedIcon/>;
    }

    return <OtherActivity/>;
}


export const TrackView: FunctionComponent<TrackProps> = ({
    studentId,
    track,
    isReadOnly,
    isStatisticsCheckbox,
    isStub,
    currentUser
 }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { pageConfig } = useContext(PageContext) as PageContextProps;

    const [isSkipForStat, setIsSkipForStat] = useState(Boolean(track.skipforstat));
    const [statProgress, setStatProgress] = useState(false);
    const TrackIcon = getIcon(track?.type);

    const handlePremiumLink = () => {
        if (isReadOnly) return;

        sendEvent(EventType.STUDENT_PREMIUM_TRACKS, { user: currentUser });
    }

    const handleCheckboxClick = async (track: Track, status: boolean) => {
        if (!studentId) return;
        
        setStatProgress(true);
        const newStatus = await sendSkipForStat(studentId, track.id, status);

        setIsSkipForStat(newStatus);        
        setStatProgress(false);
    }

    const getStubText = () => {
        if (!currentUser) {
            return `${t('trackHidden')} ${t('register')}`;
        } 

        return `${t('trackHidden')} ${t('becomePremium')}`
    }

    return (<div className={classes.resultContainer}>
        {
            isStub ? 
            <Link
                href={!currentUser ? (pageConfig?.authUrl || "/login") : "/premium"}
                className={classes.extensionLink}
                onClick={handlePremiumLink}
            >
                <div className={classes.cell}>
                    { TrackIcon }<span className={classes.text}>{ getStubText() }</span>
                </div>
            </Link>
            : <Box>
                <Box sx={{ 
                    display: "inline-flex",
                    flexWrap: "wrap"
                }}>
                    <Link 
                        href={`/activity?aid=${track.id}`}
                        className={classes.extensionLink}
                    >
                        <div className={classes.cell}>{ TrackIcon }<span className={classes.text}>{`${track.distance} ${t('km')}`}</span></div>
                        {
                            track.type === TrackType.RIDE ? 
                            <div className={classes.cell}><SpeedIcon/>
                                <span className={classes.text}>{`${_isNumber(track.avg_speed) ? track.avg_speed : "–"} ${t('kmh')}`}</span>
                            </div>
                            : <div className={classes.cell}><TimerOutlinedIcon/>
                                <span className={classes.text}>{`${track.pace} ${t('minKm')}`}</span>
                            </div>
                        }
                    </Link>
                    {isStatisticsCheckbox && track.type === TrackType.RUN && <div className={classes.cell}>
                        { statProgress ? <CircularProgress/>
                        : <FormControlLabel
                            label={ t('removeFromStat') }
                            control={<Checkbox
                                checked={isSkipForStat}
                                onClick={() => handleCheckboxClick(track, !isSkipForStat)}
                            />}
                        /> }
                    </div>}
                </Box>
                {track.json && <ActivityMetrics json={track.json}/>}
                {track.imgUrl && <Box className={classes.imgContainer}>
                    <img src={links.origin + track.imgUrl} className={classes.img}/>
                </Box>}
                <ActionsView
                    entityId={track.id}
                    currentUserId={currentUser?.id}
                    isReadOnly={isReadOnly}
                    type={ActivityNotificationType.ACTIVITY}
                    views={track.views}
                />
            </Box>
        }
    </div>)
}

const useStyles = makeStyles(muiTheme => ({
    resultContainer: {
        marginTop: "0.5rem",
        backgroundColor: theme.primarySurfaceColor,
        padding: "0.5rem",
        borderRadius: "5px",
        width: "95%",
        boxSising: "border-box"
    },
    extensionLink: {
        display: "inline-flex",
        alignItems: "center",
        flexWrap: "wrap",
        minWidth: "10rem",
    },
    cell: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        margin: "0 0.5rem"
    },
    imgContainer: {
        margin: "0.5rem 0",
        width: "25rem",
        paddingTop: "25rem",
        position: "relative",
        overflow: "hidden",
        [muiTheme.breakpoints.down("sm")]: {
            width: "100%",
            paddingTop: "100%",
        }
    },
    img: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        
    }
}))