import React, { FunctionComponent, useRef, useState } from "react";
import { PostProps } from "/src/domain/studentTasks/StudentTasksData";
import { Club } from "/src/domain/club/ClubData";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { UpdatePostParams } from "/src/contexts/PostContext";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { NewPostKeys } from './SelectPostStatus';
import { PostEditor } from "./PostEditor";
import { TaskHeader } from "../TaskHeader";
import { PostContentView } from "./PostContentView";
import { ActionsView } from "../actions/ActionsView";
import { Editor as TinyMCEEditor } from 'tinymce';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { postStatusNameTranslation, getPostStatus } from "./SelectPostStatus";

interface PostViewProps extends Omit<PostProps, 'userid'> {
    updatePost?: (params: UpdatePostParams) => Promise<boolean>;
    removePost?: (id: string, audioOnly: boolean) => Promise<boolean>;
    isReadOnly: boolean;
    currentDate: string;
    isPreview?: boolean | 'onlyHeader';
    currentUserId?: string;
    isRepostAvailable: boolean;
    isRepostedPost: boolean;
    isRepostPreview?: boolean;
    userClub: Club | null;
    clubManagement?: Club;
    godIds?: string[] | null;
}

enum MenuAction {
    DELETE,
    DELETE_AUDIO,
    EDIT,
}

export const PostView: FunctionComponent<PostViewProps> = (props) => {
    const { t } = useTranslation();
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const [openEditor, setOpenEditor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewMode, setViewMode] = React.useState(props.viewMode);
    const [tags, setTags] = useState(props.tags || []);
    const [newPostKeys, setNewPostKeys] = useState<NewPostKeys>({
        add: [],
        del: [],
        newKeys: props.status || [],
        // TODO postsStatus
        // newKeys: [
        //     "welcomeClub222",
        //     "author95101180",
        //     "post1646",
        //     // "club212",
        //     "repost1000004464"
        // ]
    });

    const [newAudioFile, setNewAudioFile] = useState<File | null>(null);

  const postDateFormat =
    dayjs(props.currentDate).get('year') !== dayjs(props.d).get('year')
      ? dayjs(props.d).format('dddd, DD.MM.YYYY')
      : dayjs(props.d).format('dddd, DD.MM');

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClickMenuItem = async (value: MenuAction) => {
        if (!props.removePost) return;

        if (value === MenuAction.EDIT) {
            setOpenEditor(!openEditor);
        } else if (
            (value === MenuAction.DELETE || value === MenuAction.DELETE_AUDIO) &&
            confirm(t('confirmPostDeletion'))
        ) {
            const isDeleted = await props.removePost(
              props.postid,
              value === MenuAction.DELETE_AUDIO,
            );
            !isDeleted && alert(t('serverError'));
        }

        handleCloseMenu();
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const savePost = async () => {
        if (!props.updatePost) return false;

        if (!editorRef.current?.getContent()) {
            alert(t('postRequirement'));
            return false;
        }

        setLoading(true);


        // Если статус меняется из draft, то обновить дату на сегодня
        const postDate = newPostKeys.del.some((key) => key.startsWith('draft')) 
            ? dayjs(props.currentDate).format("YYYY-MM-DD") : props.d;

        const isSent = await props.updatePost({
            postId: props.postid,
            date: postDate,
            text: editorRef.current.getContent(),
            viewMode: viewMode,
            audio: newAudioFile,
            audiosec: props.audiosec,
            tags: tags,
            keys: newPostKeys.newKeys,
            addKeys: newPostKeys.add,
            delKeys: newPostKeys.del,
        });

        if (!isSent) {
            setLoading(false);
            alert(t('serverError'));
            return false;
        }

        setLoading(false);
        setOpenEditor(!openEditor);

        return true;
    }
    const isGod = Boolean(props.currentUserId && props.godIds && props.godIds.includes(props.currentUserId));
    const isEditable = (!props.isReadOnly || isGod) && props.updatePost;

    const postStatus = props.currentUserId == props.userInfo?.id && !!props.status?.length ? getPostStatus(props.status) : undefined;

    return <Box sx={{
        backgroundColor: "primary.light",
        borderRadius: "5px",
        p: "1rem 1.2rem",
        m: "1rem 0",
    }}>
        {!isEditable && props.userInfo ? <TaskHeader
            studentInfo={props.userInfo ? {
                id: props.userInfo.id,
                firstName: props.userInfo.firstName || '',
                lastName: props.userInfo.lastName || '',
                avatarUrl: props.userInfo.avatarUrl || '',
                isPremium: props.userInfo.isPremium,
            }: undefined}
            date={props.d}
            currentDate={props.currentDate}
            currentUserId={props.currentUserId}
        />
        : <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Box sx={{
                fontSize: "1.2rem",
                fontWeight: "bold"
            }}>{ postDateFormat }</Box>
            {isEditable && <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openMenu ? 'long-menu' : undefined}
                    aria-expanded={openMenu ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                >
                    <MoreHorizIcon/>
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                    'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => handleClickMenuItem(MenuAction.EDIT)}>
                        <EditIcon />
                        { t('edit') }
                    </MenuItem>
                    {Boolean(props.audiosec) && (
                        <MenuItem
                          onClick={() => handleClickMenuItem(MenuAction.DELETE_AUDIO)}
                        >
                          <RemoveCircleIcon />
                          {t('deleteAudio')}
                        </MenuItem>
                    )}
                    {!props.isReadOnly && <MenuItem onClick={() => handleClickMenuItem(MenuAction.DELETE)}>
                        <DeleteIcon />
                        { t('delete') }
                    </MenuItem>}
                </Menu>  
            </>}
        </Box>}
        { postStatus && <Box sx={{
            textAlign: 'right',
            mt: '0.75rem',
        }}>
            { t(postStatusNameTranslation[postStatus]) }
        </Box> }
        <PostContentView {...props}/>
        <PostEditor
            open={openEditor}
            onClose={() => setOpenEditor(!openEditor)}
            editorRef={editorRef}
            loading={loading}
            postId={props.postid}
            savePost={savePost}
            initialValue={props.txt}
            viewMode={viewMode}
            setViewMode={setViewMode}
            currentUserId={props.currentUserId}
            audiosec={props.audiosec}
            newAudioFile={newAudioFile}
            setNewAudioFile={setNewAudioFile}
            tags={tags}
            setTags={setTags}
            // TODO postsStatus
            // postKeys={[
            //     "welcomeClub222",
            //     "author95101180",
            //     "post1646",
            //     // "club212",
            //     "repost1000004464"
            // ]}
            postKeys={props.status}
            newPostKeys={newPostKeys}
            setNewPostKeys={setNewPostKeys}
            userClub={props.userClub}
            postOwnerInfo={props.userInfo}
            clubManagement={props.clubManagement}
        />
        <ActionsView
            entityId={props.postid}
            currentUserId={props.currentUserId}
            isReadOnly={props.isReadOnly}
            type={ActivityNotificationType.BLOG}
            views={props.views}
            sx={{
                mt: "0.75rem"
            }}
            isRepostAvailable={props.isRepostAvailable}
            isRepostedPost={props.isRepostedPost}
            isRepostPreview={props.isRepostPreview}
        />
    </Box>
}
