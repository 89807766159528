import React, { FunctionComponent, useState } from 'react';
import { UpcomingRaceData } from "/src/domain/challenges/UpcomingRace";
import { get as _get } from 'lodash';
import axios from 'axios'
import { links } from '/src/domain/shared/links';

export interface UpcomingRaceContextProps {
    getUpcomingRaces: (startReg?: number, organization?: string) => void;
    racesData: RacesData;
}

export type RacesData = UpcomingRaceData[] | null;

export const UpcomingRaceContext = React.createContext<UpcomingRaceContextProps | undefined>(undefined)

export const UpcomingRaceProvider: FunctionComponent = ({ children }) => {
    const [racesData, setRacesData] = useState<RacesData>(null);

    const getUpcomingRaces = async (startReg?: number, organization?: string) => {
        try {
            const response = await axios.get(`${links.challengesOrigin}/api/banners/get`, {
                params: {
                    startreg: startReg,
                    org: organization,
                },
                withCredentials: true,
            }
            )
            setRacesData(response.data.result);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <UpcomingRaceContext.Provider
            value={{
                getUpcomingRaces,
                racesData
            }}>{children}</UpcomingRaceContext.Provider>
    )
}
